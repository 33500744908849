<template>
  <div class="project-body">
    <Navbar />
    <div class="project">
      <h1>Refonte d'un site</h1>
      <p>
        Projet réalisé à l'aide d'Adobe Xd et InDesign.
      </p>
      <p>
        Dans quel cadre ? : passage diplôme
      </p>
    </div>

    <div class="container-project">
      <img
        class="first"
        src="@/assets/images/portfolio/chefprojet/chefPro-2.png"
        data-aos="zoom-in"
        data-aos-duration="1000"
      />
      <p>
        Deux cahiers des charges (technique et fonctionnel) permettent de
        comprendre simplement plusieurs aspects.<br />
        <br />
        L'existant, les moyens mis en oeuvre, la concurrence, la cible,
        etc...<br />
      </p>
    </div>
    <div class="container-project1">
      <p>
        Une démonstration à partir d'Adobe Xd que ce soit les mockups ou la
        maquette ont une importance pour convaincre le client.<br />
      </p>
      <img
        class="second"
        src="@/assets/images/portfolio/chefprojet/chefPro-1.png"
        data-aos="zoom-in"
        data-aos-duration="1000"
      />
    </div>
    <p>
      <router-link
        class="btn btn-dark mt-2 float-lg-center mb-lg-5 btn-sm"
        :to="{ name: 'Portfolio' }"
      >
        <fontAwesomeIcon :icon="['fas', 'arrow-left']" class=" menuColor" />
        Retour vers le portfolio
      </router-link>
    </p>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "Refonte",
  components: { Navbar, Footer }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/projetView.scss";
</style>
